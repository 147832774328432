






















import { Component, Vue } from 'vue-property-decorator';
import BoxPage from '../../../components/BoxPage.vue';
import ControlLabel from '../../../components/ControlLabel.vue';
import BoxContent from '../../../components/Box/BoxContent.vue';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import ControlError from '../../../components/ControlError.vue';

import { SsrCtx, WithAsyncData } from '@/core/vue.types';
import {
  TemporaryProblemsService,
  TemporaryProblemViewModel,
} from '@/modules/sts/temporary-problems';
import { resolveFromCtx } from '@/themes/v1/fns/inject-resolver';
import { getModule } from 'vuex-module-decorators';
import { ProblemsStore } from '@/themes/v1/stores/problems.store';

@Component({
  components: {
    BoxPage,
    ControlLabel,
    BoxContent,
    ValidationObserver,
    ValidationProvider,
    ControlError,
  },
})
export default class Unsubscribe extends Vue implements WithAsyncData {
  problem: TemporaryProblemViewModel | undefined;
  success!: boolean;

  async asyncData(ctx: SsrCtx) {
    const problemsStore = getModule(ProblemsStore, ctx.store);

    const temporaryProblemsService = resolveFromCtx<TemporaryProblemsService>(
      'temporaryProblemsService',
      ctx
    );

    try {
      let problem = await temporaryProblemsService.getSingle(
        ctx.app.$i18n.locale,
        ctx.route.params.problemId
      );

      if (problem != null) {
        problemsStore.setUnsubscribedProblem(problem);
      } else {
        if (problemsStore.unsubscribedProblem != null) {
          problem = problemsStore.unsubscribedProblem;
        } else {
          throw 'Unknown problem';
        }
      }
      await temporaryProblemsService.unsubscribe(
        ctx.route.params.problemId,
        ctx.route.query.subscription as string
      );
      return {
        problem,
        success: true,
      };
    } catch {
      return {
        problem: null,
        success: false,
      };
    }
  }
}
