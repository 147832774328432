<template>
  <div class="control-error m-t--lv4"><slot></slot></div>
</template>

<script>
export default {
  name: 'ControlError',
};
</script>

<style scoped></style>
