<template>
  <div class="control-label">
    <div class="control-label__text"><slot></slot></div>
    <div v-if="$slots.right" class="control-label__extra">
      <slot name="right"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ControlLabel',
};
</script>

<style scoped></style>
