<template>
  <div class="box-page">
    <div v-if="title" class="box-page__title">
      <h1 v-html="title"></h1>
    </div>
    <Box
      theme="half"
      class="box-page__box"
      :opacity="$bps('xs') ? 70 : 80"
      :disableTopDecoration="disableTopDecoration"
      :disableBottomDecoration="disableBottomDecoration"
    >
      <slot></slot>
    </Box>
  </div>
</template>

<script>
import Box from '@themes/v1/components/Box/Box';
export default {
  name: 'BoxPage',
  components: { Box },
  props: {
    title: String,
    disableTopDecoration: {
      type: Boolean,
      default: false,
    },
    disableBottomDecoration: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style scoped></style>
